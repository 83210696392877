<template>
  <div
    ref="dropdownContainer"
    :class="[disableDefaultStyling ? 'ct-dropdown--custom' : 'ct-dropdown--default']"
  >
    <DropdownSelectButton
      v-bind="{
        optionsAreLinks,
        options,
        disableDefaultStyling,
        background,
        icon,
        isOpen: showOptions
      }"
      @click="toggleOptions()"
    >
      <slot>
        {{ dropdownTitle }}
      </slot>
    </DropdownSelectButton>
    <div
      v-if="showOptions && options.length > 1"
      :class="[disableDefaultStyling ? 'ct-dropdown__options--custom' : 'ct-dropdown__options--default']"
    >
      <DropdownSelectOption
        v-for="(option, index) in options"
        :key="`option.name${index}`"
        v-bind="{
          currentChosen: model,
          optionsAreLinks,
          disableDefaultStyling,
          background,
          ...option
        }"
        @click="clickOption"
      >
        {{ option.text }}
      </DropdownSelectOption>
    </div>
  </div>
</template>
<script setup lang="ts">
import { DropdownSelectProps, DropdownTypeIs, Option } from "@/types/Components/dropdownSelect"
import { computed, ref } from "vue"
import { onClickOutside } from "@vueuse/core"
import { DropdownOptionLink, DropdownOptionText } from "@/types/entrypoints"
import DropdownSelectOption from "@/components/DropdownSelect/Option.vue"
import DropdownSelectButton from "@/components/DropdownSelect/Button.vue"

const model = defineModel<string | number>()
const props = defineProps<DropdownSelectProps>()
const emit = defineEmits<{
	click: [option: string | number]
}>()
const dropdownContainer = ref(null)
const showOptions = ref(props.expandAll || false)
const dropdownTitle = computed(() => {
	if (props.dropdownType === DropdownTypeIs.SELECT) {
		for (const option of options.value) {
			if (option.value === model.value) {
				return option.text
			}
		}
	}
	return props.title
})
if (!props.disableClickOutside) {
	onClickOutside(dropdownContainer, () => toggleOptions(false))
}
function toggleOptions(toggle?: boolean) {
	showOptions.value = typeof toggle === "boolean" ? toggle : !showOptions.value
}
const options = computed(() => {
	const _options: Option[] = []
	if (props.options) {
		for (const option of props.options) {
			const { text } = option
			if (props.optionsAreLinks) {
				const _option = option as DropdownOptionLink
				_options.push({
					text,
					value: _option.internalFileLink ?? _option.externalFileLink ?? option.value ?? "",
					openInNewWindow: _option.downloadFileInNewWindow
				})
			}
			else {
				const { value } = option as DropdownOptionText
				_options.push({
					text,
					value
				})
			}
		}
	}
	return _options
})
function clickOption(value: string | number) {
	toggleOptions(false)
	if (props.dropdownType === DropdownTypeIs.SELECT) {
		model.value = value
		emit("click", model.value as string | number)
	}
}
</script>

<style scoped lang="postcss">
.ct-dropdown {
  &--default {
    @apply relative w-fit;
  }

  &__button {
    &--default {
      @apply flex justify-center items-center gap-3 px-6 py-3 h-9 md:h-12;
    }
  }

  &__options {
    &--default {
      @apply mt-1 flex flex-col absolute right-0 min-w-full shared-tailwind__z-index--dropdown-options;
    }
  }
}
</style>
