<template>
  <a
    v-if="optionsAreLinks && options.length === 1"
    :class="buttonClasses.button"
    :href="String(options[0].value)"
    :target="options[0].openInNewWindow ? '_blank' : ''"
    @click="clickOption(options[0].value)"
  >

    <slot />
    <Icon
      :icon
      :background
      :is-open
    />
  </a>
  <button
    v-else
    :class="buttonClasses.button"
    @click="clickOption()"
  >
    <slot />
    <Icon
      :icon
      :background
      :is-open
    />
  </button>
</template>

<script setup lang="ts">
import { Option } from "@/types/Components/dropdownSelect"
import { computed } from "vue"
import Icon from "@/components/DropdownSelect/Icon.vue"
import { DropdownSelectBackground, DropdownSelectIcon } from "@/types/backend"

const props = defineProps<{
	optionsAreLinks: boolean
	disableDefaultStyling?: boolean
	background?: DropdownSelectBackground
	options: Option[]
	icon?: DropdownSelectIcon
	isOpen: boolean
}>()
const backgroundClass = computed(() => {
	if (props.disableDefaultStyling) {
		return ""
	}
	switch (props.background) {
		case DropdownSelectBackground.PRIMARY:
			return "ct-dropdown-button__background--primary"
		case DropdownSelectBackground.TRANSPARENT:
			return "ct-dropdown-button__background--transparent"
		case DropdownSelectBackground.PURPLE_WITH_WHITE_BACKGROUND:
			return "ct-dropdown-button__background--purple-with-white-background"
		case DropdownSelectBackground.PRIMARY_WITH_WHITE_BACKGROUND:
			return "ct-dropdown-button__background--primary-with-white-background"
		default:
			return "ct-dropdown-button__background--transparent"
	}
})
const buttonClasses = computed(() => {
	if (props.disableDefaultStyling) {
		return {
			button: ["ct-dropdown-button--custom"]
		}
	}
	else {
		return {
			button: ["ct-dropdown-button--default", backgroundClass.value]
		}
	}
})

const emit = defineEmits<{
	click: [option?: string | number]
}>()
function clickOption(value?: string | number) {
	emit("click", value)
}
</script>

<style scoped lang="postcss">
.ct-dropdown-button {
  &--default {
    @apply flex items-center gap-3 min-h-12 custom-font__button-text shrink-0;
  }

  &__background {
    &--primary {
      @apply px-3 lg:px-6 bg-primary text-black;
    }

    &--transparent {
      @apply px-4 py-3 lg:px-6 lg:py-0 bg-transparent text-white border-[0.094rem] lg:border-2 border-white border-solid;
    }

    &--primary-with-white-background {
      @apply flex justify-between items-center border border-primary bg-white p-3 border-solid;
    }

    &--purple-with-white-background {
      @apply flex justify-between items-center border border-secondary bg-white p-3 border-solid;
    }
  }
}
</style>
