<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M19 12C19.5523 12 20 12.4477 20 13L20 17C20 17.7957 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20L3 20C2.20435 20 1.44129 19.6839 0.878678 19.1213C0.31607 18.5587 -7.77873e-07 17.7956 -7.43094e-07 17L-5.68248e-07 13C-5.44107e-07 12.4477 0.447715 12 0.999999 12C1.55228 12 2 12.4477 2 13L2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18L17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17L18 13C18 12.4477 18.4477 12 19 12Z"
      fill="currentColor"
    />
    <path
      d="M15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071L4.29289 8.70711C3.90237 8.31658 3.90237 7.68342 4.29289 7.29289C4.68342 6.90237 5.31658 6.90237 5.70711 7.29289L9 10.5858L9 1C9 0.447715 9.44771 -4.61255e-07 10 -4.37114e-07C10.5523 -4.12973e-07 11 0.447715 11 1L11 10.5858L14.2929 7.29289C14.6834 6.90237 15.3166 6.90237 15.7071 7.29289Z"
      fill="currentColor"
    />
  </svg>
</template>
