import { DropdownsRaw } from "@/types/entrypoints"
import { createApp } from "vue"
import DropdownSelect from "@/components/DropdownSelect/Index.vue"
import { DropdownTypeIs } from "@/types/Components/dropdownSelect"

export function renderDropdown(dropdownsRaw: DropdownsRaw, parentElement?: string) {
	// dropdown Buttons
	if (dropdownsRaw) {
		for (const dropdownOptions of Object.values(dropdownsRaw)) {
			const elementId = dropdownOptions?.elementId ?? ""
			const elementExist = parentElement ? document.querySelector(`#${parentElement} #${elementId}`) : document.getElementById(elementId)
			if (elementExist) {
				createApp(DropdownSelect, {
					...dropdownOptions,
					dropdownType: DropdownTypeIs.DROPDOWN
				}).mount(`#${elementId}`)
			}
		}
	}
}
