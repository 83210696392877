<template>
  <a
    v-if="optionsAreLinks"
    :class="optionClasses.backgroundClasses"
    :href="String(value)"
    :target="openInNewWindow ? '_blank' : ''"
    @click="clickOption(value)"
  >
    <span :class="optionClasses.textClasses">
      <slot />
    </span>
  </a>
  <button
    v-else
    :class="optionClasses.backgroundClasses"
    @click="clickOption(value)"
  >
    <span :class="optionClasses.textClasses">
      <slot />
    </span>
  </button>
</template>

<script setup lang="ts">
import { DropdownSelectBackground } from "@/types/backend"
import { Option } from "@/types/Components/dropdownSelect"
import { computed } from "vue"

const props = defineProps<
	{
		optionsAreLinks: boolean
		disableDefaultStyling?: boolean
		background?: DropdownSelectBackground
		currentChosen: string | number | undefined
	} & Omit<Option, "text">
>()
const emit = defineEmits<{
	click: [option: string | number]
}>()
function clickOption(value: string | number) {
	emit("click", value)
}
const optionClasses = computed(() => {
	const backgroundClasses = []
	const textClasses = []
	if (props.disableDefaultStyling) {
		backgroundClasses.push("ct-dropdown-option--custom")
		textClasses.push("ct-dropdown-option__text--custom")
	}
	else {
		textClasses.push("ct-dropdown-option__text")
		switch (props.background) {
			case DropdownSelectBackground.PURPLE_WITH_WHITE_BACKGROUND:
				backgroundClasses.push("ct-dropdown-option--purple-with-white-background")
				if (props.value === props.currentChosen) {
					backgroundClasses.push("ct-dropdown-option--active")
				}
				break
			case DropdownSelectBackground.PRIMARY:
				backgroundClasses.push("ct-dropdown-option--primary")
				if (props.value === props.currentChosen) {
					backgroundClasses.push("ct-dropdown-option--active")
				}
				break
			case DropdownSelectBackground.PRIMARY_WITH_WHITE_BACKGROUND:
				backgroundClasses.push("ct-dropdown-option--primary-with-white-background")
				if (props.value === props.currentChosen) {
					backgroundClasses.push("ct-dropdown-option--active")
				}
				break
			default:
				backgroundClasses.push("ct-dropdown-option--default")
				break
		}
	}
	return {
		backgroundClasses,
		textClasses
	}
})
</script>

<style scoped lang="postcss">
.ct-dropdown-option {
  &--default {
    @apply w-full flex items-center gap-2.5 bg-extraDarkGrey px-3 py-2.5 text-white cursor-pointer hover:text-black hover:bg-primary;
  }

  &__text {
    @apply custom-font__sml-body-text-2;
  }

  &--primary {
    @apply flex items-center gap-3 p-3 bg-white hover:bg-primary hover:text-white;
  }

  &--primary-with-white-background {
    @apply flex items-center gap-3 p-3 bg-white hover:bg-primary hover:text-white;
  }

  &--purple-with-white-background {
    @apply flex items-center gap-3 p-3 bg-white hover:bg-secondary hover:text-white;
  }

  &--active {
    @apply bg-primary/90 text-white;
  }
}
</style>
