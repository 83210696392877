/***
 * all enums values here are the values returns from backend so make sure they are synced
 * Some values in app/frontend/types/esri.ts are also used in the backend
 */

// Below are mainly used in config/alchemy/elements.yml if you change here then change there too.
export enum DISPLAY_DATA_OF_MAP {
	DISTRIBUTION_THE_WORLDS_PA_AND_OECMS = "distribution_the_worlds_pa_and_oecms",
	NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS = "national_sub_regional_coverage_pa_and_oecms",
	KEY_BIODIVERSITY_AREAS = "key_biodiversity_areas",
	NCP = "ncp",
	EXTENT_PA_OECM_COVERAGE_ECOREGIONS = "extent_pa_oecm_coverage_ecoregions",
	SPI = "spi",
	PRO_NET = "pro_net",
	PAI = "pai",
	PROT_CONN = "prot_conn",
	PARC = "parc",
	MANAGEMENT_EFFECTIVENESS_ASSESSMENTS_PER_COUNTRY_SUB_REGION = "management_effectiveness_assessments_per_country_sub_region",
	NATIONAL_SUB_REGIONAL_COVERAGE_PA_AND_OECMS_ITTS = "national_sub_regional_coverage_pa_and_oecms_itts"
}
export enum MAP_RESOURCES {
	PP_LIVE_24_CPT3_WDPA = "PP_Live_24_Cpt3_WDPA",
	PP_LIVE_24_CPT3_WDOECM = "PP_Live_24_Cpt3_WDOECM",

	PP_LIVE_24_CPT3_WDPA_NAT = "PP_Live_24_Cpt3_WDPA_Nat",
	PP_LIVE_24_CPT3_WDPA_NAT_OECMS = "PP_Live_24_Cpt3_WDPA_Nat_OECMs",
	PP_LIVE_24_CPT3_WDPA_REG = "PP_Live_24_Cpt3_WDPA_Reg",
	PP_LIVE_24_CPT3_WDPA_REG_OECMS = "PP_Live_24_Cpt3_WDPA_Reg_OECMs",
	PP_Live_24_Cpt3_WDPA_NAT_PARENT = "PP_Live_24_Cpt3_WDPA_Nat_Parent",
	PP_Live_24_Cpt3_WDPA_NAT_PARENT_OECMS = "PP_Live_24_Cpt3_WDPA_Nat_Parent_OECMs",

	PP_LIVE_24_CPT3_KBA_NAT = "PP_Live_24_Cpt3_KBA_Nat",
	PP_LIVE_24_CPT3_KBA_OECMS = "PP_Live_24_Cpt3_KBA_Oecms",

	PP_LIVE_24_CPT4_NCP_NAT = "PP_Live_24_Cpt4_NCP_Nat",
	PP_LIVE_24_CPT4_NCP_NAT_OECMS = "PP_Live_24_Cpt4_NCP_Nat_OECMs",
	PP_LIVE_24_CPT4_NCP_REG = "PP_Live_24_Cpt4_NCP_Reg",
	PP_LIVE_24_CPT4_NCP_REG_OECMS = "PP_Live_24_Cpt4_NCP_Reg_OECMs",

	PP_LIVE_24_CPT5_ECO_MARINE_WDPAS = "PP_Live_24_Cpt5_ECO_Marine_WDPAs_Update",
	PP_LIVE_24_CPT5_ECO_TERR_WDPAS = "PP_Live_24_Cpt5_ECO_Terr_WDPAs",
	PP_LIVE_24_CPT5_ECO_FRESH_WDPAS = "PP_Live_24_Cpt5_ECO_Fresh_WDPAs_Update",
	PP_LIVE_24_CPT5_ECO_TERR_OECMS = "PP_Live_24_Cpt5_ECO_Terr_OECMS",
	PP_LIVE_24_CPT5_ECO_MARINE_OECMS = "PP_Live_24_Cpt5_ECO_Marine_OECMs",
	PP_LIVE_24_CPT5_ECO_FRESH_OECMS = "PP_Live_24_Cpt5_ECO_Fresh_OECMs",

	PP_LIVE_24_CPT5_SPI = "PP_Live_24_Cpt5_SPI",
	PP_LIVE_24_CPT5_SPI_OECMS = "PP_Live_24_Cpt5_SPI_OECMs",
	PP_LIVE_24_CPT5_SPI_REG = "PP_Live_24_Cpt5_SPI_Reg",
	PP_LIVE_24_CPT5_SPI_REG_OECMS = "PP_Live_24_Cpt5_SPI_Reg_Oecms",

	PP_LIVE_24_CPT6_PRONET_NAT = "PP_Live_24_Cpt6_ProNet_Nat",
	PP_LIVE_24_CPT6_PRONET_NAT_OECMS = "PP_Live_24_Cpt6_ProNet_Nat_OECMs",
	PP_LIVE_24_CPT6_PRONET_REG = "PP_Live_24_Cpt6_ProNet_Reg",
	PP_LIVE_24_CPT6_PRONET_REG_OECMS = "PP_Live_24_Cpt6_ProNet_Reg_OECMs",

	PP_LIVE_24_CPT6_PAI_NAT = "PP_Live_24_Cpt6_PAI_Nat",
	PP_LIVE_24_CPT6_PAI_REG = "PP_Live_24_Cpt6_PAI_Reg",

	PP_LIVE_24_CPT6_PROTCONN = "PP_Live_24_Cpt6_ProtConn",
	PP_LIVE_24_CPT6_PROTCONN_OECMS = "PP_Live_24_Cpt6_ProtConn_OECMs",
	PP_LIVE_24_CPT6_PROTCONN_REG = "PP_Live_24_Cpt6_ProtConn_Reg",
	PP_LIVE_24_CPT6_PROTCONN_REG_OECMS = "PP_Live_24_Cpt6_ProtConn_Reg_OECMs",

	PP_LIVE_24_CPT6_PARC_NAT = "PP_Live_24_Cpt6_PARC_Nat",
	PP_LIVE_24_CPT6_PARC_NAT_OECMS = "PP_Live_24_Cpt6_PARC_Nat_OECMs",
	PP_LIVE_24_CPT6_PARC_REG = "PP_Live_24_Cpt6_PARC_Reg",
	PP_LIVE_24_CPT6_PARC_REG_OECMS = "PP_Live_24_Cpt6_PARC_Reg_OECMs",

	PP_LIVE_24_CPT7_PAME_NAT = "PP_Live_24_Cpt7_PAME_Nat",
	PP_LIVE_24_CPT7_PAME_REG = "PP_Live_24_Cpt7_PAME_Reg",

	// The two resources below are the same layers used in National and sub-regional coverage of protected areas and OECMs.
	// In short, 'National and sub-regional coverage of protected areas and OECMs and ITTs' PA+OECMs map looks exactly same as the
	// 'National and sub-regional coverage of protected areas and OECMs' PA+OECMs map
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PP_LIVE_24_CPT9_OECMS_NAT = "PP_Live_24_Cpt3_WDPA_Nat_OECMs",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	PP_LIVE_24_CPT9_OECMS_REG = "PP_Live_24_Cpt3_WDPA_Reg_OECMs",
	PP_LIVE_24_CPT9_ITTS_NAT = "PP_Live_24_Cpt9_ITTs_Nat",
	PP_LIVE_24_CPT9_ITTS_REG = "PP_Live_24_Cpt9_ITTs_Reg"
}
export enum DISPLAY_DATA_OF_CLUSTERED_BAR_CHART {
	PADDD = "paddd",
	TARGET_TRACKER_REGIONAL = "target_tracker_regional",
	MEAN_COVERAGE_ALL_KBA_SUBREGIONAL = "mean_coverage_all_kba_subregional"
}
export enum DISPLAY_DATA_OF_GAUGE_CHART {
	TARGET_TRACKER = "target_tracker"
}
export enum DISPLAY_DATA_OF_LINE_CHART {
	CHANGE_IN_PA_AND_OECM_SINCE_1990 = "change_in_pa_and_oecm_since_1990",
	CHANGE_IN_KBAS_BY_PA_AND_OECM_SINCE_2000 = "change_in_kbas_by_pa_and_oecm_since_2000"
}
export enum DISPLAY_DATA_OF_PIE_CHART {
	GOVERNANCE = "governance"
}
export enum DISPLAY_DATA_OF_STACKED_CHART {
	NCP_COVERAGE = "ncp_coverage",
	GOVERNANCE_REGIONAL = "governance_regional",
	COVERAGE_OF_BIOGEOGRAPHIC_REGIONS = "coverage_of_biogeographic_regions",
	ADDITIONAL_GLOBAL_COVERAGE_ITTS = "additional_global_coverage_itts",
	ADDITIONAL_COVERAGE_ITTS_TO_TERRESTRIAL_ECOREGIONS = "additional_coverage_itts_to_terrestrial_ecoregions",
	GOVERANCE_SUBREGIONAL = "governance_subregional",
	INLAND_WATERS_COVERAGE = "inland_waters_coverage"
}
export type DISPLAY_DATA_OF_ALL_CHARTS = DISPLAY_DATA_OF_CLUSTERED_BAR_CHART |
	DISPLAY_DATA_OF_GAUGE_CHART |
	DISPLAY_DATA_OF_LINE_CHART |
	DISPLAY_DATA_OF_PIE_CHART |
	DISPLAY_DATA_OF_STACKED_CHART

export enum DropdownSelectBackground {
	TRANSPARENT = "Transparent",
	PRIMARY = "Primary",
	PURPLE_WITH_WHITE_BACKGROUND = "Purple_with_white_background",
	PRIMARY_WITH_WHITE_BACKGROUND = "Primary_with_white_background"
}
export enum DropdownSelectIcon {
	DOWNLOAD = "Download",
	ARROW = "Arrow",
	NO_ICON = "No_icon"
}
export enum HeroStyleFor {
	HOME_PAGE = "HomePage",
	BLOG = "Blog"
}
export enum ChapterIs {
	CHAPTER = "CHAPTER",
	RECOMMENDATION = "RECOMMENDATIONS"
}
export enum TARGET_LINE {
	MARINE = "Marine",
	TERRESTRIAL = "Terrestrial",
	MARINE_KBA = "Marine KBA",
	TERRESTRIAL_KBA = "Terrestrial KBA",
	FRESHWATER_KBA = "Freshwater KBA"
}
/**
 * Below are mainly used for legend colours, they are mainly the values that are same as improted csv columns.
 * below are mainly the values in self.xxxxxx_xtype_chart functions in app/models/*.rb
 * The values here are the legend/y axis values in the imported csv from backend make sure to match them up for getting right colours
 * */
export enum Legend {
	BLACK = "black",
	TERRESTRIAL = "terrestrial",
	MARINE = "marine",
	TERRESTRIAL_KBA = "terrestrial_kba",
	MARINE_KBA = "marine_kba",
	FRESHWATER_KBA = "freshwater_kba",
	TARGET = "target",
	COVERAGE_TARGET = "coverage_target",
	PROTECTION_TOTAL_WDPA = "protection_total_wdpa",
	OECM_ADDTION = "oecm_addition",
	WDPA = "wdpa",
	DEGAZETTEMENT = "Degazettement",
	DOWNGRADE = "Downgrade",
	DOWNSIZE = "Downsize",
	PA = "pa",
	ITT = "itt",
	OECMS = "oecms",

	// Governance

	FEDERAL_NATIONAL = "Federal or national ministry or agency",
	SUB_NATIONAL_MINISTRY = "Sub-national ministry or agency",
	GOVERNMENT_DELEGATED = "Government-delegated management",

	COLLABORATIVE_GOVERNANCE = "Collaborative governance",
	JOINT_GOVERNANCE = "Joint governance",
	TRASNBOUNDARY_GOVERNANCE = "Transboundary governance",

	INDIVIDUAL_LANDOWNERS = "Individual landowners",
	NON_PROFIT_ORG = "Non-profit organisations",
	FOR_PROFIT = "For-profit organisations",

	INDIGENOUS_PEOPLE = "Indigenous peoples",
	LOCAL_COMMUNITIES = "Local communities",

	MIXED = "Mixed",
	NOT_REPORTED = "Not Reported",
	// GOVERANCE_SUBREGIONAL
	GOVERNANCE_BY_GOV = "governance_by_government",
	SHARED_GOVERANCE = "shared_governance",
	PRIVATE_GOVERANCE = "governance_by_private_individuals_or_organisations",
	GOVERANCE_BY_INDIGENOUS_PEOPLES_AND_LOCAL_COMMUNITIES = "governance_by_indinous_peoples_and_or_local_communities",
	MIXED_GOVERANCE_SUBREGIONAL = "mixed",
	NOT_REPORTED_GOVERANCE_SUBREGIONAL = "not_reported",
	// biogeographic_regions
	ECOREGIONS = "ecoregions",
	BIOMES = "biomes",
	PROVINCES = "provinces",
	FRESHWATER = "freshwater",
	PELAGIC = "pelagic",
	TRANSTIONAL = "transitional"
}
/**
 * Below are in config/vite.rb
 * below are mainly the values in self.xxxxxx_xtype_chart functions in app/models/*.rb
 * The values here are the legend/y axis values in the imported csv from backend make sure to match them up for getting right colours
 * */
export enum VITE_LINKS {
	SHARE = "share",
	FACEBOOK = "facebook",
	LINKEDIN = "linkedin",
	X = "x",
	EMAIL = "email",
	WCMC = "wcmc",
	IUCN = "iucn",
	WCPA = "wcpa",
	PP = "pp"
}
/***
 * change anything here please also change app/helpers/application_helper.rb
 */
export type ChartSettings = {
	min?: number
	max?: number
	unit?: string
	show_data_in_every_x_interval?: number
	all_legends?: Legend[]
	all_legends_map?: Record<string, Legend>
	y_axis_label?: string
	x_axis_label?: string
	show_y_axis_labels: boolean
} | undefined
