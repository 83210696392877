<template>
  <IconArrow
    v-if="icon === DropdownSelectIcon.ARROW"
    :class="iconClasses"
  />
  <IconDownload
    v-else-if="icon === DropdownSelectIcon.DOWNLOAD"
    :class="iconClasses"
  />
</template>

<script setup lang="ts">
import { DropdownSelectIcon } from "@/types/backend"
import IconArrow from "@/components/Icon/Arrow.vue"
import IconDownload from "@/components/Icon/Download.vue"
import { computed } from "vue"

const props = defineProps<{
	disableDefaultStyling?: boolean
	icon?: DropdownSelectIcon
	isOpen?: boolean
}>()
const iconClasses = computed(() => {
	const _iconClasses = []
	switch (props.icon) {
		case DropdownSelectIcon.ARROW:
			_iconClasses.push("ct-dropdown-icon--arrow")
			if (props.isOpen) {
				_iconClasses.push("ct-dropdown-icon--rotate")
			}
			break
		case DropdownSelectIcon.DOWNLOAD:
			_iconClasses.push("ct-dropdown-icon--download")
			break
		default:
			break
	}
	return _iconClasses
})
</script>

<style scoped lang="postcss">
.ct-dropdown-icon {
  &--arrow {
    @apply w-[0.656rem] h-1.5 lg:w-3.5 lg:h-2;
  }

  &--download {
    @apply w-4 h-4 lg:w-5 lg:h-5;
  }

  &--rotate {
    @apply rotate-180;
  }
}
</style>
