import { DropdownOptions } from "@/types/entrypoints"

export type Option = {
	text: string
	value: string | number
	openInNewWindow?: boolean
}
export enum DropdownTypeIs {
	DROPDOWN,
	SELECT
}
export type DropdownSelectProps = {
	expandAll?: boolean
	disableDefaultStyling?: boolean
	disableClickOutside?: boolean
	dropdownType: DropdownTypeIs
} & DropdownOptions
