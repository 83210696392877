<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 8"
    fill="none"
  >
    <path
      d="M7 5.58579L1.70711 0.292893C1.31658 -0.0976314 0.683417 -0.0976314 0.292893 0.292893C-0.097631 0.683417 -0.097631 1.31658 0.292893 1.70711L6.29289 7.70711C6.68342 8.09763 7.31658 8.09763 7.70711 7.70711L13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292894C13.3166 -0.0976313 12.6834 -0.0976313 12.2929 0.292894L7 5.58579Z"
      fill="currentColor"
    />
  </svg>
</template>
